import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/Marianne-Thin.woff2\",\"weight\":\"100\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Marianne-Light.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Marianne-Regular.woff2\",\"weight\":\"normal\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Marianne-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Marianne-Bold.woff2\",\"weight\":\"bold\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Marianne-ExtraBold.woff2\",\"weight\":\"800\",\"style\":\"normal\"}],\"variable\":\"--font-marianne\"}],\"variableName\":\"marianne\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/_components/MainLayoutProviders.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/error/ErrorContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/layout/Footer.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/locales/initClient.ts");
